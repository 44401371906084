import axios, { AxiosResponse } from 'axios';
import authService from '../../services/auth-service';
import { API_ENDPOINT, UDS_AUFGABENTYP_FUHRPARK } from '../../constants';
import { AnyObject, ResponseModel } from 'common/interfaces';
import {
  CrmName,
  GetVehicleForCreateTaskResponse,
  IVehicleCustomValidationArgs,
  TaskModelResponse,
  TaskResponseItem,
  TaskTypeId
} from './task.prop';
import { cloneDeep } from 'lodash';
import { ConditionOperator } from 'common/enums';

export const getRecords = async (params: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmTask/getTasksByContact`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: params
  });
};

export const getTasksByContactFilterData = async () => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/crmTask/getTasksByContactFilterData`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const getVehicleForCreateTask = async (
  AccountsId: string[]
): Promise<AxiosResponse<ResponseModel<GetVehicleForCreateTaskResponse[]>>> => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/crmTask/GetFuhrparksForCreateTask`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    data: {
      AccountsId
    }
  });
};

export const createNewTask = async (CrmTask: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmTask/Create`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    data: {
      CrmTask
    }
  });
};

export const getCurrentUserTasks = async (tableParams: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmTask/ListWithCurrentUserTasks`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      ...tableParams
    }
  });
};

export const getListWithCurrentUserTasksFilterData = async () => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmTask/ListWithCurrentUserTasksFilterData`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {}
  });
};

export const uploadCompanyDocuments = async (
  Files: File[],
  EntityName: string,
  regardingobjectid: string,
  description: string
) => {
  const token = await authService.acquireTokenAsync();
  const data = new FormData();

  Files.forEach(file => {
    data.append('Files', file);
  });
  data.append('EntityName', EntityName);
  data.append('Guid', regardingobjectid);
  data.append('Description', description);
  data.append('AccessRights', 'Kunde');

  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/sharpoint/UploadFile`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const archivedTask = async (Id: string, Date: string) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmTask/SetTaskStatusCanceled`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      Id,
      Date
    }
  });
};

export const uploadDate = async (Date: string | Date | null, crmTask: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmTask/SetTaskStatusDone`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    data: {
      Date,
      crmTask
    }
  });
};

export const getAllActiveCars = async (companyIdList: any) => {
  const token = await authService.acquireTokenAsync();

  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/crmEntity/getEntityByFieldQuery`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    data: {
      entityName: 'new_fuhrpark',
      columns: ['new_name', 'new_fuhrparkid', 'new_fahrerid', 'statecode'],
      conditions: [
        {
          attributeTypeCode: 6,
          attributeName: 'new_sparteid',
          conditionOperator: ConditionOperator.In,
          value: companyIdList
        },
        {
          attributeTypeCode: 11,
          attributeName: 'statecode',
          conditionOperator: ConditionOperator.Equal,
          value: [{ value: '0' }]
        }
      ]
    }
  });
};

export const sendEmail = async (id: string) => {
  const token = await authService.acquireTokenAsync();

  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmTask/SendReminderToDriver`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    data: {
      id
    }
  });
};

export const formatDate = (date: string) => {
  if (new Date(date).getTime()) {
    return new Date(date).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }

  return '-';
};

export const ellipsisString = (string: string, length: number) => {
  if (!string) {
    return;
  }
  if (string.length <= length) {
    return string;
  }

  return `${string.slice(0, length)}...`;
};

export const imgDependingOnTypeTask = (taskTypeCode: number = TaskTypeId.Allgemein) => {
  switch (taskTypeCode) {
    case UDS_AUFGABENTYP_FUHRPARK.uvv:
    case UDS_AUFGABENTYP_FUHRPARK.inspectionDates:
      return '/assets/images/aufgeklappte-aufgabe-UVV.png';

    case UDS_AUFGABENTYP_FUHRPARK.hu:
      return '/assets/images/aufgeklappte-aufgabe-HU.png';

    case UDS_AUFGABENTYP_FUHRPARK.allgemein:
      return '/assets/images/aufgeklappte-aufgabe-Allgemein.png';

    case UDS_AUFGABENTYP_FUHRPARK.checklist:
      return '/assets/images/aufgeklappte-aufgabe-Checklist.png';

    case UDS_AUFGABENTYP_FUHRPARK.reparaturfreigabe:
    case UDS_AUFGABENTYP_FUHRPARK.replacement:
      return '/assets/images/kva-aufgaben.png';

    case UDS_AUFGABENTYP_FUHRPARK.return:
      return '/assets/images/aufgeklappte-aufgabe-Vehicle-Return.jpg';

    case UDS_AUFGABENTYP_FUHRPARK.leaseExtension:
      return '/assets/images/aufgeklappte-aufgabe-lease-extension-decision.png';

    case UDS_AUFGABENTYP_FUHRPARK.appraise:
      return '/assets/images/aufgeklappte-aufgabe-Appraise.png';

    default:
      return '';
  }
};

export const descriptionDependingOnTaskType = (taskType: number) => {
  if (taskType === UDS_AUFGABENTYP_FUHRPARK.hu) {
    return 'HU/AU Berichte';
  }
  if (taskType === UDS_AUFGABENTYP_FUHRPARK.uvv) {
    return 'UVV Berichte';
  }
  if (taskType === UDS_AUFGABENTYP_FUHRPARK.allgemein) {
    return 'Sonstiges';
  }
  if (taskType === UDS_AUFGABENTYP_FUHRPARK.reparaturfreigabe) {
    return 'Reparaturfreigabe';
  }
  if (taskType === UDS_AUFGABENTYP_FUHRPARK.inspectionDates) {
    return 'Prüfbericht';
  }
};

export const cancelTask = async (data: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmTask/SetTaskStatusCanceled`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const restoreTask = async (data: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmTask/RestoreTask`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const getDelegateList = async (data: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmTask/GetPersonsToDelegate`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const delegateTask = async (data: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmTask/DelegateToTask`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const postponeTask = async (data: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmTask/SetTaskPostponeStatusVerschoben`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const getChecklistFile = async () => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'GET',
    url: `${API_ENDPOINT}/api/CrmTask/GetChecklistFile`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const closeChecklistTask = async (id: string, files: File[]) => {
  const token = await authService.acquireTokenAsync();
  const data = new FormData();

  files.forEach(file => {
    data.append('Files', file);
  });
  data.append('TaskId', id);

  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmTask/CloseChecklistTask`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const getInfoForCreateTask = async (carId?: string) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmTask/GetInfoForCreateTaskPost`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      RegardingObjectId: carId
    }
  });
};

export const modifyTableData = (taskResponse: TaskModelResponse) => {
  if (!taskResponse) {
    return;
  }

  const cloneData = cloneDeep(taskResponse) as any;

  cloneData.tasks = cloneData?.tasks?.map((task: TaskResponseItem) => ({
    ...task.task[0],
    ...(task.fuhrpark ? task.fuhrpark[0] : {}),
    ...(task.leaseEndTaskInfo ? task.leaseEndTaskInfo : {})
  }));

  return cloneData;
};

export const noNeedReplacementVehicle = async (
  TaskId: string | null,
  FuhrparkId: string | null,
  IsCancelNoNeed?: boolean
) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/FuhrparkLeaseEnd/SetNoNeedReplacementVehicle`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      TaskId,
      FuhrparkId,
      IsCancelNoNeed
    }
  });
};

export const setDriversPolicies = async (FahrerId: string, CarPolicyId: string, MobilitatbudgetId: string) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/FuhrparkLeaseEnd/SetDriversPolicies`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      FahrerId,
      CarPolicyId,
      MobilitatbudgetId
    }
  });
};

export const getDriversPolicies = async (FahrerId: string) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/FuhrparkLeaseEnd/GetDriversPolicies`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      FahrerId
    }
  });
};

export const vehicleCustomValidation = ({ values, formFields }: IVehicleCustomValidationArgs) => {
  const formColumns = cloneDeep(formFields.columns);
  let validatedCarId = values[CrmName.Vehicle] || '';

  formColumns.map(column => {
    if (column.name === CrmName.Vehicle) {
      column.fields.map(field => {
        field.data?.map((fieldData: AnyObject) => {
          if (fieldData.id === validatedCarId) {
            if (fieldData.isDisable) {
              validatedCarId = '';
            }
          }
        });
      });
    }
  });

  return !validatedCarId;
};

export const uploadProtocol = async (
  vehicleId: string,
  orderId: string,
  files: File[],
  isWithoutProtocol: boolean,
  returnDate: string
) => {
  const token = await authService.acquireTokenAsync();

  const data = new FormData();
  files.forEach(file => {
    data.append('Files', file);
  });
  data.append('FuhrparkId', vehicleId);
  data.append('LAFId', orderId);
  data.append('NoNeedUploadState', isWithoutProtocol.toString());
  data.append('ReturnDate', returnDate);

  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/FuhrparkLeaseEnd/VehicleReturnUploadHandoverProtocol`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

export const editTask = async (data: AnyObject) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/CrmTask/UpdateAlgemeinTaskFields`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};

interface SetLeaseExtensionDataProps {
  FahrzeugId: string;
  LvaId: string;
  PrevPhaseNo: number;
  NewPhaseNo: number;
  TaskId: string;
  Mileage?: number;
  Months?: number;
  Comment?: string;
  HiddenExtend?: boolean;
  HiddenExtendDate?: string;
  NoAskCancelStatus?: boolean;
  RealMileage?: number;
}

interface SetLeaseExtensionPhaseProps {
  data: SetLeaseExtensionDataProps;
}

interface SetLeaseExtensionWithFileDataProps extends SetLeaseExtensionDataProps {
  files: File[];
}

export const setLeaseExtensionPhase = async ({ data }: SetLeaseExtensionPhaseProps) => {
  const token = await authService.acquireTokenAsync();
  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/FuhrparkLeaseEnd/SetLvaPhase`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: data
  });
};

export const setLeaseExtensionPhaseUploadFile = async ({
  FahrzeugId,
  LvaId,
  NewPhaseNo,
  PrevPhaseNo,
  TaskId,
  files,
  Comment,
  HiddenExtend,
  Mileage,
  Months
}: SetLeaseExtensionWithFileDataProps) => {
  const token = await authService.acquireTokenAsync();

  const data = new FormData();
  files.forEach(file => {
    data.append('Files', file);
  });
  data.append('FahrzeugId', FahrzeugId);
  data.append('LvaId', LvaId);
  data.append('PrevPhaseNo', JSON.stringify(PrevPhaseNo));
  data.append('NewPhaseNo', JSON.stringify(NewPhaseNo));
  data.append('TaskId', TaskId);
  Comment && data.append('Comment', Comment);
  HiddenExtend && data.append('HiddenExtend', JSON.stringify(HiddenExtend));
  Mileage && data.append('HiddenExtend', JSON.stringify(Mileage));
  Months && data.append('HiddenExtend', JSON.stringify(Months));

  return axios({
    method: 'POST',
    url: `${API_ENDPOINT}/api/FuhrparkLeaseEnd/SetLvaPhaseUploadFile`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
};
