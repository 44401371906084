import React, { useState } from 'react';
import { Divider, Flex, Text } from 'common/common-components.styled';
import Step from '../step';
import { Gallery } from 'components/gallery/image-gallery/gallery';
import { defaultCarImageUrl } from '../../../constants';
import {
  StyledCarPolicy,
  StyledConfirmButton,
  StyledDeclineButton,
  StyledFieldSection,
  StyledGalleryContainer,
  StyledStepsContainer
} from '../release-external-page.styled';
import { AnyObject } from 'common/interfaces';
import CustomAccordion from 'components/custom-accordion/custom-accordion';
import useReleaseData from '../hooks/use-release-data';
import useSteps from '../hooks/use-steps';
import useVehicleDetailsFields from '../hooks/use-vehicle-details-fields';
import useCarPhotos from '../hooks/use-car-photos';
import useVehicleExpandSections from '../hooks/use-vehicle-expand-sections';
import EditableFieldMain from 'pages/existing-vehicle-page/editable-field-main';
import ReleaseExternalPlaceholder from '../release-external-placeholder';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { vehicleRequestConfig } from 'request-config/vehicle/vehicle.request-config';
import { useQuery } from 'hooks';
import BudgetScale from 'pages/details/budget-scale/budget-scale';
import useVehicleApprovalPage from '../hooks/use-vehicle-approval-page';
import { getExpectedReferenceRate } from 'pages/car-configurator/hooks/use-info-containers-data';

export enum ApproveStatuses {
  WaitingForApprove = 100000001,
  Approved = 100000002,
  Declined = 100000003
}

const ApproveComponent = () => {
  const query = useQuery();
  const queryId = query.id as string;

  const [status, setStatus] = useState<ApproveStatuses>(ApproveStatuses.WaitingForApprove);

  const { vehicleOfferData, driverData, carPolicies, globalVehicleOfferData } = useReleaseData();
  const { vehicleApprovalPageData } = useVehicleApprovalPage();
  const { steps, activeStep } = useSteps(status);
  const { vehicleMainDetailsFields } = useVehicleDetailsFields();
  const { carPhotos } = useCarPhotos();
  const { vehicleExpandSections, onToggleVehicleExpandSections } = useVehicleExpandSections();
  const { fetch } = useFetch({ loadingKey: 'setExternalConfigurationStatus' });

  const setExternalConfigurationStatus = (statusCode: ApproveStatuses) => {
    const scrollContainer = document.querySelector('#UnauthorizedPageScrollContainer');
    if (scrollContainer) {
      scrollContainer.scrollTo({ top: 0, behavior: 'smooth' });
    }

    fetch({
      requestConfig: vehicleRequestConfig.setExternalConfigurationStatus({ id: queryId, statusCode }),
      callback: () => {
        setStatus(statusCode);
      }
    });
  };

  return (
    <div>
      <Flex direction='column' align='center' margin='10px 0 100px 0'>
        <StyledStepsContainer justify='space-between'>
          {steps.map((step, index) => (
            <Step key={index} steps={steps} currentStep={step} isActive={activeStep === index} index={index} />
          ))}
        </StyledStepsContainer>
      </Flex>

      {status === ApproveStatuses.WaitingForApprove && (
        <Flex direction='column' align='center'>
          <Flex width='100%' maxWidth='1000px' direction='column'>
            <Text size='34' color='blue' bottom='10'>
              Anfrage zur Bestellung eines Fahrzeuges
            </Text>
            <Text size='24' color='blue' bottom='30'>
              Bitte geben Sie folgende Bestellung für {driverData?.driver.attributes.fullname || ''} frei.
            </Text>
            <Text bottom='10' bold size='16' color='blackPearl'>
              Konfiguration: {globalVehicleOfferData?.uds_configurationtypecode?.label || ''}
            </Text>
            <Flex wrap='wrap' bottom='40'>
              <Text bottom='10' right='30' size='16' color='blackPearl'>
                Fahrer: {driverData?.driver.attributes.fullname || ''}
              </Text>
              <Text bottom='10' right='30' size='16' color='blackPearl'>
                Car-Policy: {driverData?.carPolicy.attributes.uds_name || ''}
              </Text>
              <Text bottom='10' size='16' color='blackPearl'>
                Mobilitätsbudget: {driverData?.budget.attributes.uds_name || ''} -{' '}
                {driverData?.budget.attributes.uds_budget_eur?.label || ''}
              </Text>
            </Flex>
            <Text bold size='20' color='grey900' bottom='10'>
              {`${vehicleOfferData?.uds_carbrandid?.name || ''} ${vehicleOfferData?.uds_carmodelid?.name || ''}`}
            </Text>
            <Text bottom='10' color='grey500'>
              Anfragenummer: {vehicleOfferData?.uds_name}
            </Text>
            <Flex wrap='wrap' align='center'>
              <Text right='10' bottom='10' color='blackPearl'>
                Car-Policy:
              </Text>
              {carPolicies?.map(carPolicy => (
                <StyledCarPolicy key={carPolicy.id}>{carPolicy.uds_name}</StyledCarPolicy>
              ))}
            </Flex>

            <StyledGalleryContainer margin='40px 0'>
              <Flex position='relative' width='60%'>
                <Gallery images={carPhotos} gallery={[]} placeholder={defaultCarImageUrl} />
              </Flex>
              <Flex width='40%' direction='column'>
                {vehicleMainDetailsFields.map((section, i) => (
                  <Flex key={i}>
                    {section.map(field => (
                      <StyledFieldSection key={field.name}>
                        <EditableFieldMain
                          disableHover={field.disabled}
                          edit={() => {}}
                          cancelEdit={() => {}}
                          save={() => {}}
                          data={field}
                        />
                        <div className='mobexo-divider' />
                      </StyledFieldSection>
                    ))}
                  </Flex>
                ))}
                {vehicleApprovalPageData?.uds_leasingangeboteid && (
                  <BudgetScale
                    budget={vehicleApprovalPageData.budget}
                    fuelCost={vehicleApprovalPageData.monthly_fuel_costs}
                    leasingPrice={getExpectedReferenceRate({
                      netListPrice: vehicleApprovalPageData.uds_listenpreis_netto?.value || 0,
                      leasingFactor: vehicleApprovalPageData.uds_leasingfaktor || 0,
                      leaseServices: vehicleApprovalPageData.lease_services || 0
                    })}
                    surcharge={vehicleApprovalPageData.absoluteDownPayment}
                  />
                )}
              </Flex>
            </StyledGalleryContainer>

            <Flex justify='flex-end' top='50'>
              <StyledDeclineButton onClick={() => setExternalConfigurationStatus(ApproveStatuses.Declined)} right='10'>
                Ablehnen
              </StyledDeclineButton>
              <StyledConfirmButton onClick={() => setExternalConfigurationStatus(ApproveStatuses.Approved)}>
                Freigeben
              </StyledConfirmButton>
            </Flex>

            <Text bold size='20' color='grey900' top='20'>
              Fahrzeug
            </Text>

            {vehicleExpandSections.map(
              (section: AnyObject, i: number) =>
                !section.hidden && (
                  <div key={i}>
                    <CustomAccordion section={section} key={section.name} onToggle={onToggleVehicleExpandSections} />
                    {i < vehicleExpandSections.length - 1 && <Divider />}
                  </div>
                )
            )}
          </Flex>
        </Flex>
      )}

      {status === ApproveStatuses.Approved && (
        <ReleaseExternalPlaceholder title='Vielen Dank!' description='Sie haben die Freigabe erfolgreich erteilt.' />
      )}

      {status === ApproveStatuses.Declined && (
        <ReleaseExternalPlaceholder title='Vielen Dank!' description='Sie haben die Freigabe abgelehnt.' />
      )}
    </div>
  );
};

export default ApproveComponent;
