import classnames from 'classnames';
import { last, takeRight } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { withPageContainer } from '../../hoc/withPageContainer';
import classes from './breadcrumbs-panel-dashboard.module.scss';
import DashboardTitle from '../dashboard-title';
import InfoChip from '../info-chip';
import { useDispatch, useSelector } from 'react-redux';
import { setSideMenuOpen, setSnakbar } from '../../actions/app_action';
import MainRoleSwitcher from '../main-role-switcher';
import { Distance, Text } from '../../common/common-components.styled';
import { useTypedSelector } from '../../hooks/use-typed-selector';
import { getDeviceType } from '../../utils/get-device-type';
import { DeviceTypes, USER_ROLES } from '../../constants';
import Tooltip from '@mui/material/Tooltip';
import { PAGES, PrefixForId } from '../../common/enums';
import HelmetComponent from 'components/helmet-component/helmet-component';
import classNames from 'classnames';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import IconHover from '../icon-hover';
import { useTheme } from 'styled-components';
import { LogoutIcon } from '../icons-new/logout';
import { useMsal } from '@azure/msal-react';
import { useUnsavedChangesModal } from '../../hooks/modals/unsaved-changes.modal/unsaved-changes.modal';
import { CacheIcon } from '../icons-new/cache';
import { clearCRMCache } from '../../services/maintenance-service';
import useOverlay from '../../hooks/use-overlay';
import { AnyObject } from 'common/interfaces';
import { IUrlTreeNode } from 'common/interfaces';
import { BreadcrumbsPanelDashboardProps } from './breadcrumbs-panel-dashboard.props';
import useUnsavedChangesLink from 'hooks/use-unsaved-changes/use-unsaved-changes-link';

export const defaultUrlTree: IUrlTreeNode = {
  url: PAGES.DASHBOARD,
  breadcrumbTitle: 'Dashboard',
  title: '',
  children: {
    aufgaben: {
      breadcrumbTitle: 'aufgaben',
      title: 'Aufgaben',
      url: PAGES.TASKS
    },
    reparaturstatus: {
      breadcrumbTitle: 'reparaturstatus',
      title: 'Reparaturstatus',
      url: PAGES.REPAIRED
    },
    dienstleisteraufgaben: {
      breadcrumbTitle: 'dienstleisteraufgaben',
      title: 'Dienstleisteraufgaben',
      url: PAGES.SERVICE_PROVIDER_TASKS
    },
    dashboard: {
      breadcrumbTitle: 'dashboard',
      title: DashboardTitle,
      url: PAGES.DASHBOARD
    },
    'einrichtungs-assistent': {
      breadcrumbTitle: 'Einrichtungsassistent',
      title: 'Einrichtungsassistent',
      url: PAGES.SETUP_ASSISTANT
    },
    mobilitatsbudget: {
      breadcrumbTitle: 'Mobilitätsbudget',
      title: 'Mobilitätsbudget',
      url: PAGES.MOBILITY_BUDGET
    },
    'car-policy': {
      breadcrumbTitle: 'Car-Policy',
      title: 'Car-Policy',
      url: PAGES.CAR_POLICY
    },
    details: {
      breadcrumbTitle: 'Details',
      title: 'Details',
      url: PAGES.VEHICLE_OFFER_DETAILS
    },
    'meine-fahrzeuge': {
      breadcrumbTitle: 'Fahrzeuge',
      title: 'Fahrzeuge',
      url: PAGES.MY_VEHICLES,
      children: {
        fahrzeuge: {
          breadcrumbTitle: 'Kennzeichen',
          title: 'Kennzeichen anzeigen',
          url: PAGES.MY_VEHICLES_DETAILS
        },
        'neue-fahrzeuge': {
          breadcrumbTitle: 'Fahrzeug anlegen',
          title: 'Fahrzeug anlegen',
          url: PAGES.MY_VEHICLES_NEW_VEHICLE
        }
      }
    },
    'meine-anfragen': {
      breadcrumbTitle: 'Anfragen',
      title: 'Anfragen',
      url: PAGES.MY_REQUESTS
    },
    'meine-fahrer': {
      breadcrumbTitle: 'Fahrer',
      title: 'Fahrer',
      url: PAGES.MY_DRIVERS,
      children: {
        'fahrer-anlegen': {
          breadcrumbTitle: 'Fahrer einladen',
          title: 'Fahrer einladen',
          url: PAGES.MY_DRIVERS_CREATE
        },
        'fahrer-bearbeiten': {
          breadcrumbTitle: '',
          title: '',
          url: PAGES.MY_DRIVERS_DETAILS
        }
      }
    },
    fahrzeugkosten: {
      breadcrumbTitle: 'Fahrzeugkosten',
      title: 'Fahrzeugkosten',
      url: PAGES.VEHICLE_COSTS
    },
    fuhrerscheinkontrolle: {
      breadcrumbTitle: 'Führerscheinkontrolle',
      title: 'Führerscheinkontrolle',
      url: PAGES.DRIVERS_CHECK
    },
    'meine-schaden': {
      breadcrumbTitle: 'Schäden',
      title: 'Schäden',
      url: PAGES.DAMAGE,
      children: {
        schaden: {
          breadcrumbTitle: '',
          title: '',
          url: ''
        },
        ['schaden-anlegen']: {
          breadcrumbTitle: 'Schaden anlegen',
          title: 'Schaden anlegen',
          url: PAGES.CREATE_DAMAGE
        }
      }
    },
    konfigurationen: {
      breadcrumbTitle: 'Konfigurationen',
      title: 'Konfigurationen',
      url: PAGES.CONFIGURATIONS,
      children: {
        fahrzeugdetails: {
          breadcrumbTitle: 'Fahrzeugdetails',
          title: 'Fahrzeugdetails',
          url: PAGES.CONFIGURATION_DETAILS
        },
        'neues-fahrzeug-konfigurieren': {
          breadcrumbTitle: 'Neues Fahrzeug konfigurieren',
          title: 'Neues Fahrzeug konfigurieren',
          url: PAGES.NEW_CONFIGURATIONS
        }
      }
    },
    mitarbeiterkonfigurationen: {
      breadcrumbTitle: 'Mitarbeiterkonfigurationen',
      title: 'Mitarbeiterkonfigurationen',
      url: PAGES.EMPLOYEE_CONFIGURATIONS,
      children: {
        fahrzeugdetails: {
          breadcrumbTitle: 'Fahrzeugdetails',
          title: 'Fahrzeugdetails',
          url: PAGES.EMPLOYEE_CONFIGURATION_DETAILS
        }
      }
    },
    referenzfahrzeuge: {
      breadcrumbTitle: 'Referenzfahrzeuge',
      title: 'Referenzfahrzeuge',
      url: PAGES.REFERENCE_VEHICLES,
      children: {
        'neues-fahrzeug-konfigurieren': {
          breadcrumbTitle: 'Referenzfahrzeuge',
          title: 'Referenzfahrzeuge',
          url: PAGES.NEW_REFERENCE_CONFIGURATIONS
        },
        fahrzeugdetails: {
          breadcrumbTitle: 'Fahrzeugdetails',
          title: 'Fahrzeugdetails',
          url: PAGES.REFERENCE_VEHICLES_DETAILS
        }
      }
    },
    ['neue-konfiguration']: {
      breadcrumbTitle: 'Neue Konfiguration',
      title: 'Neue Konfiguration',
      url: PAGES.NEW_CONFIGURATION
    },
    monatsrechnungen: {
      breadcrumbTitle: 'Monatsrechnungen',
      title: 'Monatsrechnungen',
      url: PAGES.MONTHLY_BILLS
    },
    bestellungen: {
      breadcrumbTitle: 'Bestellungen',
      title: 'Bestellungen',
      url: PAGES.ORDERS,
      children: {
        bestellung: {
          breadcrumbTitle: 'Bestellungen',
          title: 'Bestellung',
          url: PAGES.ORDER_DETAILS
        }
      }
    },
    dokumente: {
      breadcrumbTitle: 'Vertragsdokumente',
      title: 'Vertragsdokumente',
      url: PAGES.DOCUMENTS,
      children: {
        vertragsdokumente: {
          breadcrumbTitle: 'Dokumente',
          title: 'Dokumente',
          url: PAGES.DOCUMENTS_CONTRACT
        }
      }
    },
    vertrage: {
      breadcrumbTitle: 'Rahmenverträge',
      title: 'Rahmenverträge',
      url: PAGES.CONTRACTS
    },
    kontoeinstellungen: {
      breadcrumbTitle: 'Kontoeinstellungen',
      title: 'Kontoeinstellungen',
      url: PAGES.ACCOUNT_SETTINGS
    },
    unternehmen: {
      breadcrumbTitle: 'Unternehmenseinstellungen',
      title: 'Unternehmenseinstellungen',
      url: PAGES.COMPANIES
    },
    list: {
      breadcrumbTitle: 'Unternehmens-Einstellungen',
      title: 'Unternehmens-Einstellungen',
      url: PAGES.LIST
    },
    fahrzeuganfragen: {
      breadcrumbTitle: 'Fahrzeuganfragen',
      title: 'Fahrzeuganfragen',
      url: PAGES.REQUESTS,
      children: {
        anfrage: {
          breadcrumbTitle: 'Anfrage',
          title: 'Anfrage',
          url: PAGES.REQUESTS_DETAILS
        }
      }
    },
    leasinganfragen: {
      breadcrumbTitle: 'Leasinganfragen',
      title: 'Leasinganfragen',
      url: PAGES.LEASING_REQUESTS,
      children: {
        anfrage: {
          breadcrumbTitle: 'Leasinganfrage',
          title: 'Leasinganfrage',
          url: PAGES.LEASING_REQUESTS_DETAILS
        }
      }
    },
    'kraftstoff-report': {
      breadcrumbTitle: 'Kraftstoff Report',
      title: 'Kraftstoff Report',
      url: PAGES.FUEL_REPORT
    },

    tankkarten: {
      breadcrumbTitle: 'Tankkarten',
      title: 'Tankkarten',
      url: PAGES.FUEL_CARDS,
      children: {
        order: {
          breadcrumbTitle: 'Tankkarten',
          title: 'Tankkarten',
          url: PAGES.FUEL_CARDS_ORDER
        },
        ['tankkarte-bestellen']: {
          breadcrumbTitle: 'Tankkarte bestellen',
          title: 'Tankkarte bestellen',
          url: PAGES.FUEL_CARDS_ORDER_FUEL_CARD
        }
      }
    },
    'pool-car-buchungen': {
      breadcrumbTitle: 'Pool-Car Buchungen',
      title: 'Pool-Car Buchungen',
      url: PAGES.POOL_CALENDAR,
      children: {
        fahrzeuge: {
          breadcrumbTitle: 'Kennzeichen',
          title: 'Kennzeichen anzeigen',
          url: PAGES.MY_POOL_CALENDAR_VEHICLE
        },
        ['neue-buchung']: {
          breadcrumbTitle: 'Neue Buchung',
          title: 'Neue Buchung',
          url: PAGES.POOL_CALENDAR_NEW
        }
      }
    },
    'meine-pool-car-buchungen': {
      breadcrumbTitle: 'Meine Pool-Car Buchungen',
      title: 'Meine Pool-Car Buchungen',
      url: PAGES.MY_POOL_CALENDAR,
      children: {
        fahrzeuge: {
          breadcrumbTitle: 'Kennzeichen',
          title: 'Kennzeichen anzeigen',
          url: PAGES.MY_POOL_CALENDAR_VEHICLE
        },
        ['neue-buchung']: {
          breadcrumbTitle: 'Neue Buchung',
          title: 'Neue Buchung',
          url: PAGES.MY_POOL_CALENDAR_NEW
        }
      }
    },
    kontakt: {
      breadcrumbTitle: 'Kontakt',
      title: 'Kontakt',
      url: PAGES.SUPPORT
    },
    auftrage: {
      breadcrumbTitle: 'Aufträge',
      title: 'Aufträge',
      url: PAGES.ASSIGNMENTS,
      children: {
        fahrzeuguberfuhrung: {
          breadcrumbTitle: 'Fahrzeugüberführung',
          title: 'Fahrzeugüberführung',
          url: PAGES.ASSIGNMENTS_VEHICLE_TRANSFER
        },
        fahrzeuguberfuhrungRecord: {
          breadcrumbTitle: '',
          title: '',
          url: PAGES.ASSIGNMENTS_VEHICLE_TRANSFER_RECORD
        },
        unfallreparatur: {
          breadcrumbTitle: 'Unfallreparatur',
          title: 'Unfallreparatur',
          url: PAGES.ASSIGNMENTS_ACCIDENT_REPAIR
        },
        unfallreparaturRecord: {
          breadcrumbTitle: '',
          title: '',
          url: PAGES.ASSIGNMENTS_ACCIDENT_REPAIR_RECORD
        },
        leasingrueckgabe: {
          breadcrumbTitle: 'Leasingrückgabe',
          title: 'Leasingrückgabe',
          url: PAGES.ASSIGNMENTS_LEASE_RETURN
        },
        leasingrueckgabeRecord: {
          breadcrumbTitle: '',
          title: '',
          url: PAGES.ASSIGNMENTS_LEASE_RETURN_RECORD
        },
        ['abrechnung-elektroauto-ladekosten']: {
          breadcrumbTitle: 'Wallbox Abrechnung',
          title: 'Wallbox Abrechnung',
          url: PAGES.ASSIGNMENTS_ELECTRIC_CAR_CHARGING_COST
        },
        ['tankquittung-einreichen']: {
          breadcrumbTitle: 'Tankquittung einreichen',
          title: 'Tankquittung einreichen',
          url: PAGES.ASSIGNMENTS_FUEL_RECEIPT_SUBMIT
        }
      }
    },
    swagger: {
      breadcrumbTitle: 'API Doc',
      title: 'API Doc',
      url: PAGES.SWAGGER
    },
    langzeitmiete: {
      breadcrumbTitle: 'Langzeitmiete',
      title: 'Langzeitmiete',
      url: PAGES.LONG_TERM_RENTAL
    },
    onboarding: {
      breadcrumbTitle: 'Einrichtungsassistent',
      title: 'Datenimport',
      url: PAGES.ONBOARDING
    },
    ['pakete-preise']: {
      breadcrumbTitle: 'Pakete und Preise',
      title: 'Pakete & Preise',
      url: PAGES.PACKAGES,
      children: {
        package: {
          breadcrumbTitle: 'Professional Paket',
          title: 'Professional-Paket',
          url: PAGES.PACKAGE_PAGE
        },
        ['unternehmen-hinzufügen']: {
          breadcrumbTitle: 'Professional Paket',
          title: 'Professional-Paket',
          url: PAGES.ADD_COMPANY
        }
      }
    },
    entscheidungshilfen: {
      breadcrumbTitle: 'Entscheidungshilfen',
      title: 'Kunde werden',
      url: PAGES.DECISION_SUPPORT
    },

    ['kunden-werben-kunden']: {
      breadcrumbTitle: 'Kunden werben Kunden',
      title: 'Kunden werben Kunden',
      url: PAGES.CLIENTS_FLEETMANAGER
    },
    ['kunden']: {
      breadcrumbTitle: 'Kunden',
      title: 'Kunden',
      url: PAGES.CLIENTS_DEALER
    },
    ['kunden-einladen']: {
      breadcrumbTitle: 'Kunden einladen',
      title: 'Kunden einladen',
      url: PAGES.INVITE_CLIENT_DEALER
    },
    'chat-test': {
      breadcrumbTitle: 'Weavy chat',
      title: 'Weavy chat',
      url: PAGES.CHAT_TEST
    },
    leasingende: {
      breadcrumbTitle: 'Leasingende',
      title: 'Leasingende',
      url: PAGES.LEASE_END
    },
    import: {
      breadcrumbTitle: 'Import',
      title: 'Import',
      url: PAGES.IMPORT
    },
    'abrechnung-elektroauto-ladekosten': {
      breadcrumbTitle: 'Wallbox Abrechnung',
      title: 'Wallbox Abrechnung',
      url: PAGES.BILLING_ELECTRIC_CAR_CHARGING_COSTS
    },
    ['tankquittung-einreichen']: {
      breadcrumbTitle: 'Tankquittung einreichen',
      title: 'Tankquittung einreichen',
      url: PAGES.TANK_RECEIPT_SUBMIT
    },
    eingangsrechnungen: {
      breadcrumbTitle: 'Eingangsrechnungen',
      title: 'Eingangsrechnungen',
      url: PAGES.EINGANGSRECHNUNG,
      children: {
        'eingangsrechnung-upload': {
          breadcrumbTitle: 'Rechnung erfassen',
          title: 'Rechnung erfassen',
          url: PAGES.EINGANGSRECHNUNG
        },
        'eingangsrechnung-create': {
          breadcrumbTitle: 'Eingangsrechnung',
          title: 'Eingangsrechnung',
          url: PAGES.EINGANGSRECHNUNG
        },
        'eingangsrechnung-edit': {
          breadcrumbTitle: 'Eingangsrechnung',
          title: 'Eingangsrechnung',
          url: PAGES.EINGANGSRECHNUNG
        }
      }
    },
    ['angebot-erstellen']: {
      breadcrumbTitle: 'Angebot erstellen',
      title: 'Angebot erstellen',
      url: PAGES.CREATE_OFFER,
      children: {
        ['angebotsprozess-erstellen']: {
          breadcrumbTitle: 'Professional Paket',
          title: 'Angebot erstellen',
          url: PAGES.CREATE_OFFER_PROCESS
        }
      }
    },
    ['top-deals']: {
      breadcrumbTitle: 'Top Deals',
      title: 'Top Deals',
      url: PAGES.TOP_DEALS
    },
    freigaben: {
      breadcrumbTitle: 'Freigaben',
      title: 'Freigaben',
      url: PAGES.APPROVALS,
      children: {
        freigabe: {
          breadcrumbTitle: 'Freigabe',
          title: 'Freigabe',
          url: PAGES.APPROVALS_DETAILS
        }
      }
    },
    cockpit: {
      breadcrumbTitle: 'Portfolio Cockpit',
      title: 'Portfolio Cockpit',
      url: PAGES.GLOBAL_REFERENCE_VEHICLES
    },
    ['mietwagen-buchen']: {
      breadcrumbTitle: 'Mietwagen Buchen',
      title: 'Mietwagen Buchen',
      url: PAGES.BOOKING_RENTAL_CAR
    },
    dokumentenmanager: {
      breadcrumbTitle: 'Dokumentenmanager',
      title: 'Dokumentenmanager',
      url: PAGES.DOCUMENTMANAGER,
      children: {
        'dokumentenmanager-upload': {
          breadcrumbTitle: 'Dokument hochladen',
          title: 'Dokument hochladen',
          url: PAGES.DOCUMENTMANAGER_UPLOAD
        },
        'dokumentenmanager-edit': {
          breadcrumbTitle: 'Dokument bearbeiten',
          title: 'Dokument bearbeiten',
          url: PAGES.DOCUMENTMANAGER_EDIT
        }
      }
    },
    flotteneinstellungen: {
      breadcrumbTitle: 'Flotteneinstellungen',
      title: 'Flotteneinstellungen',
      url: PAGES.FLEET_SETTINGS
    },
    buchhaltungseinstellungen: {
      breadcrumbTitle: 'Buchhaltungseinstellungen',
      title: 'Buchhaltungseinstellungen',
      url: PAGES.ACCOUNTING_SETTINGS
    },
    ['personalisierte-e-mail-vorlage']: {
      breadcrumbTitle: 'Personalisierte E-Mail-Vorlage',
      title: 'Personalisierte E-Mail-Vorlage',
      url: PAGES.PERSONAL_EMAIL_TEMPLATE
    },
    ['cockpit-2-0']: {
      breadcrumbTitle: 'Portfolio Cockpit 2.0',
      title: 'Portfolio Cockpit 2.0',
      url: PAGES.COCKPIT_2_0
    },
    konfigurator: {
      breadcrumbTitle: 'Konfigurator',
      title: 'Konfigurator',
      url: PAGES.CAR_CONFIGURATOR_BRANDS
    },
  }
};

const deviceType = getDeviceType();

export const parseBreadcrumbs = (pathname: string, customUrlTree: IUrlTreeNode) => {
  const url = pathname.replace(/(^\/)|(\/$)/g, '');
  const routes = url.split('/');

  const urlTree = customUrlTree ? customUrlTree : defaultUrlTree;
  const breadcrumbs = [urlTree];
  let node = urlTree.children;
  routes.forEach((url: string) => {
    if (url && node) {
      const nextNode = node[url];
      if (nextNode) {
        node = nextNode.children;
        breadcrumbs.push(nextNode);
      }
    }
  });

  return takeRight(breadcrumbs, 3);
};

const BreadcrumbsPanelDashboard = (props: BreadcrumbsPanelDashboardProps) => {
  const theme = useTheme();
  const location = useLocation();
  const [showOverlay, hideOverlay] = useOverlay();
  const { instance } = useMsal();
  const [breadcrumbs, setBreadcrumbs] = useState<IUrlTreeNode[]>([]);
  const customUrlTree = useSelector((state: AnyObject): IUrlTreeNode => state['app'].customUrlTree);
  const route = last(breadcrumbs);
  const sideMenuOpen = useSelector((state: AnyObject): boolean => state['app'].sideMenuOpen);
  const dispatch = useDispatch();
  const user = useTypedSelector(state => state['app'].user);
  const { openUnsavedChangesModal } = useUnsavedChangesModal();
  const { unsavedChangesHandler } = useUnsavedChangesLink();

  const isAdmin = useIsUserHaveRole([USER_ROLES.administrator.id]);

  useEffect(() => {
    if (typeof location.pathname === 'string') {
      const breadcrumbs = parseBreadcrumbs(location.pathname, customUrlTree);
      setBreadcrumbs(breadcrumbs);
    }
  }, [location, setBreadcrumbs, customUrlTree]);

  const renderTitle = () => {
    const Title = route?.title;
    if (typeof Title === 'string') {
      return Title;
    } else if (Title) {
      const Component = Title as React.ComponentType;
      return <Component />;
    }
    return '';
  };

  const toggleSideMenu = () => {
    dispatch(setSideMenuOpen(!sideMenuOpen));
  };

  const logout = useCallback(() => {
    unsavedChangesHandler(() =>
      openUnsavedChangesModal(() => {
        instance.logout();
        localStorage.clear();
        sessionStorage.clear();
      })
    );
  }, [instance, openUnsavedChangesModal, unsavedChangesHandler]);

  const clearCache = async () => {
    showOverlay();
    let response;
    try {
      response = await clearCRMCache();
    } catch (e) {
      response = {};
      console.error(e);
    } finally {
      hideOverlay();
    }

    let snackbarData = {
      type: 'error',
      message: 'Fehler beim Löschen des Caches.'
    };
    if (response?.status === 200) {
      snackbarData = {
        type: 'success',
        message: 'CRM-Cache erfolgreich gelöscht.'
      };
    }

    dispatch(
      setSnakbar({
        isOpen: true,
        ...snackbarData
      })
    );
  };

  return (
    <div className={classes.breadcrumbs}>
      <div id={PrefixForId.BreadcrumbsPanel} className={classnames(props.containerClassName, classes.container)}>
        <div className={classes.willkommen}>
          {deviceType !== DeviceTypes.Desktop && (
            <button onClick={toggleSideMenu} className={classes.willkommen__menu}>
              <img id={'burger_menu'} src='/assets/images/burger_menu.svg' alt='burger_menu' />
            </button>
          )}
          <h3 className={classes.willkommen__text}>
            <span className={classes.willkommen__text__title} id='breadcrumbsHeaderUserName'>
              {renderTitle()}
            </span>
            {route?.infoChip && (
              <>
                <Distance side='15' />
                <Tooltip title='Dieses Feature befindet sich noch in der Testphase.'>
                  <div>
                    <InfoChip color={theme.colors.darkBlue} bgcolor={theme.colors.white} text={route.infoChip} />
                  </div>
                </Tooltip>
              </>
            )}
          </h3>
        </div>
        <div className={classNames('d-flex', 'a-i-center')}>
          {user.roles.length > 0 && deviceType !== DeviceTypes.Mobile && (
            <>
              {user.roles.length === 1 ? (
                <Text right='20' color='white'>
                  {user.mainRole.name}
                </Text>
              ) : (
                <MainRoleSwitcher textColor='white' />
              )}

              <Distance side='20' />
            </>
          )}

          {isAdmin && (
            <>
              <Tooltip title='Löschen Sie den CRM-Cache'>
                <div>
                  <IconHover height='22px' id='moreMenuItemClearTheCrmCache' pointer onClick={clearCache}>
                    <CacheIcon width={18} height={22} color={theme.colors.white} />
                  </IconHover>
                </div>
              </Tooltip>
              <Distance side='20' />
            </>
          )}

          <Tooltip title='Abmelden'>
            <div>
              <IconHover height='20px' id={PrefixForId.MoreMenuItem + PrefixForId.Logout} pointer onClick={logout}>
                <LogoutIcon color={theme.colors.white} />
              </IconHover>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default withPageContainer(BreadcrumbsPanelDashboard);
