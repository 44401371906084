import { CarConfiguratorUrlParams, GroupOptionsReturn } from './car-configurator.types';
import { PAGES } from 'common/enums';
import { OptionResponse, OptionTypes } from './hooks/use-options-by-type/use-options-by-type.types';
import { SelectionWizardData } from './selection-wizard/selection-wizard.types';

export const getConfiguratorLink = (urlParams: CarConfiguratorUrlParams): PAGES => {
  const { driverId, brandId, modelId, vehicleId } = urlParams;

  if (vehicleId && modelId && brandId) {
    return PAGES.CAR_CONFIGURATOR_OPTIONS.replace(':driver_id', driverId)
      .replace(':brand_id', brandId)
      .replace(':model_id', modelId)
      .replace(':vehicle_id', vehicleId) as PAGES;
  }
  if (modelId && brandId) {
    return PAGES.CAR_CONFIGURATOR_VEHICLES.replace(':driver_id', driverId)
      .replace(':brand_id', brandId)
      .replace(':model_id', modelId) as PAGES;
  }
  if (brandId) {
    return PAGES.CAR_CONFIGURATOR_MODELS.replace(':driver_id', driverId).replace(':brand_id', brandId) as PAGES;
  }
  return PAGES.CAR_CONFIGURATOR_BRANDS.replace(':driver_id', driverId) as PAGES;
};

export const scrollToTop = () => {
  const scrollContainer = document.querySelector('.scroll-container');
  if (scrollContainer) {
    scrollContainer.scrollTo({ top: 0 });
  }
};

export const getOptionPrice = (option: OptionResponse) => {
  return option.retailPrice ? option.retailPrice : option.sumRetailPrice;
};

export const groupOptions = (options?: OptionResponse[]): GroupOptionsReturn => {
  if (!options) {
    return {
      equipment: [],
      packages: [],
      isShowGroups: false
    };
  }
  const equipment = options.filter(option => option.optionType !== OptionTypes.Package);
  const packages = options.filter(option => option.optionType === OptionTypes.Package);
  return {
    equipment,
    packages,
    isShowGroups: !!packages.length
  };
};

export const checkIsNotAbleToRemoveOption = (data: SelectionWizardData) => {
  return (
    !data.addedOption &&
    !!data.removedOption &&
    !data.optionsToRemove.length &&
    !data.optionsToChoose.length &&
    !data.optionsWithDiscount.length &&
    !data.optionsWithNewPrice.length &&
    data.optionsToAdd.length === 1 &&
    data.optionsToAdd[0].uniqId === data.removedOption.uniqId
  );
};

export const goToBrandConfigurator = (link: string | null) => {
  link && window.open(link, '_blank');
};

export const MIN_ITEMS_COUNT = 5;
