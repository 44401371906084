import { MenuTitles, SettingsMenuItems } from 'hooks/use-settings-menu-items/use-settings-menu-items.types';
import { crmDataTypes, USER_ROLES } from '../../../constants';

export const getCompanySettingsDefaultMenuItems = (userRole: string): SettingsMenuItems[] => {
  const items = [
    {
      title: MenuTitles.BusinessDetails,
      active: true
    },
    {
      title: MenuTitles.Contact,
      active: false
    },
    {
      title: MenuTitles.Company,
      active: false
    }
  ];

  if (userRole !== USER_ROLES.workshop.id) {
    items.push({
      title: MenuTitles.Leasing,
      active: false
    });
  }

  return items;
};

export const mileageRangeOptions = {
  uds_lease_annual_milleage_k_10: {
    value: 10000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_15: {
    value: 15000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_20: {
    value: 20000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_25: {
    value: 25000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_30: {
    value: 30000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_35: {
    value: 35000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_40: {
    value: 40000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_45: {
    value: 45000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_50: {
    value: 50000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_55: {
    value: 55000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_60: {
    value: 60000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_65: {
    value: 65000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_70: {
    value: 70000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_75: {
    value: 75000,
    attributeTypeCode: crmDataTypes.Boolean
  },
  uds_lease_annual_milleage_k_80: {
    value: 80000,
    attributeTypeCode: crmDataTypes.Boolean
  }
};

export const durationOptions = {
  uds_lease_length_month_12: {
    label: '12'
  },
  uds_lease_length_month_18: {
    label: '18'
  },
  uds_lease_length_month_24: {
    label: '24'
  },
  uds_lease_length_month_30: {
    label: '30'
  },
  uds_lease_length_month_36: {
    label: '36'
  },
  uds_lease_length_month_42: {
    label: '42'
  },
  uds_lease_length_month_48: {
    label: '48'
  },
  uds_lease_length_month_54: {
    label: '54'
  },
  uds_lease_length_month_60: {
    label: '60'
  }
};
