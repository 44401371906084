/* eslint-disable prefer-template */
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import classes from './unfolded-task.module.scss';
import { UserIcon } from '../../../icons-new/user';
import { Car } from '../../../icons-new/car';
import { CalendarWithCheckMarkIcon } from 'components/icons-new/calendar-with-check-mark-icon';
import { formFields } from './formFields';
import {
  formatDate,
  uploadDate,
  uploadCompanyDocuments,
  imgDependingOnTypeTask,
  descriptionDependingOnTaskType,
  restoreTask,
  getChecklistFile,
  closeChecklistTask,
  noNeedReplacementVehicle
} from '../../utils';
import { useDispatch } from 'react-redux';
import { toggleModal } from '../../../../actions/app_action';
import {
  MODALS,
  MODAL_NAMES,
  UDS_AUFGABENTYP_FUHRPARK,
  USER_ROLES,
  REPLACEMENT_VEHICLE_PHASES
} from '../../../../constants';
import { convertUTCDateToLocalDate } from '../../../../utils/convert-UTC-date-to-local-date';
import { getFields } from '../../../form/form-view';
import { cloneDeep } from 'lodash';
import MoreMenu from 'components/more-menu/more-menu';
import { Button, Flex, Text, Divider } from 'common/common-components.styled';
import UploadReport from '../upload-report/upload-report';
import { setSnakbar } from 'actions/app_action';
import { CollapseIcon } from 'components/icons/collapse-icon';
import {
  UnfoldedTaskProps,
  DescriptionFromServerProps,
  VehicleInitialReviewPhases,
  StatusId,
  TaskTypeId
} from 'components/aufgaben-component/task.prop';
import { useTheme } from 'styled-components';
import { AnyObject } from 'common/interfaces';
import { TaskTypeIcon } from '../task-type-icon/task-type-icon';
import { useOverlay } from 'hooks';
import ChecklistUpload from '../upload-checklist/upload-checklist';
import sharepointService from 'services/sharepoint-service';
import Download from 'components/icons/download';
import { UploadTestReportIcon } from 'components/icons/upload-test-report-icon';
import { useHistory } from 'react-router-dom';
import { addTooltipToString } from '../aufgaben-item/aufgaben-item';
import { PrefixForId } from '../../../../common/enums';
import { useIsUserHaveRole } from 'hooks/use-is-user-have-role';
import NoReplacementVehicle from '../no-need-replacement/no-need-replacement';
import { encodeBase64 } from 'utils/decode-encode-base64';
import { PAGES } from 'common/enums';
import { UploadProtocol } from '../upload-protocol/upload-protocol';
import { uploadProtocol } from 'components/aufgaben-component/utils';
import EditTask from '../edit-task/edit-task';
import { Tooltip, useMediaQuery } from '@mui/material';
import { ButtonsForLeaseEnd } from './components/buttons-for-lease-extension/buttons-for-lease-extension';
import { OrderVehicleReport } from '../order-vehicle-report/order-vehicle-report';
import DOMPurify from 'dompurify';
import { fleetLeaseEndRequestConfig } from 'request-config/freet-lease-end/fleet-lease-end.request-config';
import { useFetch } from 'hooks/use-fetch/use-fetch';
import { useUnfoldedTask } from './hooks/use-unfolded-tas';
import SpinnerContainer from 'components/spinner-container';
import EllipsisWithTooltip from 'components/ellipsis-with-tooltip';
import { TaskInspectionDatesActionButton } from './components/action-button/inspection-dates/inspection-dates';
import moment from 'moment';
import OfferStatusSection from 'components/product/offer-status-section/offer-status-section';

export const UnfoldedTask = ({
  aufgeklappteAufgabeInfo,
  closeAufgeklappteAufgabe,
  unfoldedTaskSubmittedHandler,
  statusColor,
  menuItems,
  isDriver,
  setIsMenuOpen,
  index,
  isEditing,
  onCancelTaskEdit
}: UnfoldedTaskProps) => {
  const {
    activityid,
    uds_aufgabentyp_fuhrpark,
    regardingobjectid,
    uds_executor_contactid,
    scheduledend,
    subject,
    description,
    uds_b2c_portal_status,
    uds_b2cpostponedate,
    new_fahrerid,
    new_name,
    new_fuhrparkid,
    uds_b2cpostponestatus,
    last_editor,
    uds_lease_return_date
  } = aufgeklappteAufgabeInfo;

  const { taskAssets, isLoading } = useUnfoldedTask(activityid.attributeValue as string);

  const {
    upperText,
    lowerText,
    isDescriptionRender,
    rvo_state,
    lva_phaseinfo,
    lva_state,
    rvo_phaseinfo,
    vir_phaseinfo,
    vir_state,
    vr_phaseinfo
  } = taskAssets;

  const [showOverlay, hideOverlay] = useOverlay();
  const dispatch = useDispatch();
  const history = useHistory();

  const [formFieldsState, setFormFieldsState] = useState(formFields);
  const [isShortened, setIsShortened] = useState(true);
  const [checklistFileData, setChecklistFileData] = useState({
    fileId: '',
    name: ''
  });
  const reportFormStateRef = useRef<AnyObject | null>();
  const theme = useTheme();

  const taskTypeId = uds_aufgabentyp_fuhrpark?.attributeValue?.value;
  const taskStatusId = uds_b2c_portal_status?.attributeValue?.value;

  const isTaskTypeAllgemein = taskTypeId === UDS_AUFGABENTYP_FUHRPARK.allgemein;
  const isUserNotHaveCar = regardingobjectid?.attributeValue?.logicalName === 'contact';

  const descriptionLable = description?.attributeValue || '-';

  const cloneFormFields = cloneDeep(formFieldsState);

  const showMenu = useMemo(() => menuItems.find(item => !item.hidden), [menuItems]);

  const isFleetManager = useIsUserHaveRole(USER_ROLES.fleetManager.id);
  const isMobexoEmployee = useIsUserHaveRole(USER_ROLES.mobexoEmployee.id);

  const isRVOPhase4 =
    taskTypeId === UDS_AUFGABENTYP_FUHRPARK.replacement && rvo_state === REPLACEMENT_VEHICLE_PHASES.processing;

  const editTaskData = {
    taskId: activityid,
    title: subject,
    description,
    executorId: uds_executor_contactid,
    vehicleId: regardingobjectid,
    dueDate: scheduledend
  };

  const isTaskPostponed = uds_b2cpostponestatus?.attributeValue?.value === StatusId.Postponed;

  const isMobileWidth = useMediaQuery('(max-width:550px)');

  const getIsDisplayMoreMenu = () => {
    const taskTypeValue = uds_aufgabentyp_fuhrpark.attributeValue?.value;

    if (taskTypeValue) {
      return ![UDS_AUFGABENTYP_FUHRPARK.reparaturfreigabe, UDS_AUFGABENTYP_FUHRPARK.inspectionDates].includes(
        taskTypeValue
      );
    }

    return true;
  };

  const DescriptionRender = useCallback(() => {
    if (descriptionLable) {
      if (isShortened && descriptionLable?.length > 330) {
        return (
          <span onClick={() => setIsShortened(false)}>
            {`${descriptionLable?.slice(0, 300)}...`}
            <span className={classes.button_link}>Mehr anzeigen</span>
          </span>
        );
      }
      return (
        <span>
          {descriptionLable?.split('\n').map((description, indx) => (
            <span key={description + indx}>
              {description}
              <br />
            </span>
          ))}
        </span>
      );
    }
    return <span>-</span>;
  }, [descriptionLable, isShortened]);

  const DescriptionFromServer = ({ description = '' }: DescriptionFromServerProps) => {
    const cleanDescription = DOMPurify.sanitize(description);

    return <div className='Container' dangerouslySetInnerHTML={{ __html: cleanDescription }} />;
  };

  const redirectToConfiguration = useCallback(
    (itemId: string) => {
      isFleetManager
        ? history.push(`${PAGES.EMPLOYEE_CONFIGURATION_DETAILS}?id=${encodeBase64(itemId)}`)
        : history.push(`${PAGES.CONFIGURATION_DETAILS}?id=${encodeBase64(itemId)}`);
    },
    [history, isFleetManager]
  );

  const ConfigurationStatusRender = useCallback(() => {
    return (
      <Flex direction='column'>
        {rvo_phaseinfo?.progressInfo?.map((item: AnyObject) => {
          return (
            <Flex key={item.id} direction='column'>
              <Flex justify='space-between' align='center' bottom='10' top='20'>
                <Flex align='center'>
                  <Text color='grey900' right='30'>{`Konfiguration ${item.name}`}</Text>
                  <OfferStatusSection status={item.progressCode} />
                </Flex>
                <Text color='blue' pointer onClick={() => redirectToConfiguration(item.id)}>
                  Zur Konfiguration →
                </Text>
              </Flex>
              <Divider color='grey100' />
            </Flex>
          );
        })}
      </Flex>
    );
  }, [rvo_phaseinfo, redirectToConfiguration]);

  const closeAlert = useCallback(() => {
    setIsMenuOpen(false);
    dispatch(toggleModal(MODALS.alert, null));
  }, [dispatch, setIsMenuOpen]);

  useEffect(() => {
    getFields(cloneFormFields, (field: AnyObject) => {
      if (field.name === 'regardingobjectid') {
        let validation = null;

        if (isTaskTypeAllgemein) {
          validation = field.validation?.filter((item: any) => item.name === 'required');
        } else {
          validation = [{ name: 'required' }];
        }

        if (isUserNotHaveCar) {
          field.hidden = true;
        } else {
          field.hidden = false;
        }

        field.validation = validation;
      }
    });

    setFormFieldsState(cloneFormFields);
  }, []);

  useEffect(() => {
    getChecklistFile().then(response => {
      setChecklistFileData({ fileId: response.data?.data[0]?.fileId, name: response.data.data[0]?.name });
    });
  }, []);

  const onReportFormComplete = useCallback((files: Blob[], date: string | null = null) => {
    reportFormStateRef.current = {
      files,
      date
    };
  }, []);

  const submitChecklistReport = useCallback(() => {
    closeAlert();
    showOverlay();
    reportFormStateRef.current &&
      closeChecklistTask(activityid?.attributeValue || '', Object.values(reportFormStateRef.current?.files || {})).then(
        response => {
          dispatch(
            setSnakbar({
              isOpen: true,
              message: response.data.isSuccess
                ? 'Die Checkliste wurde erfolgreich versendet'
                : response.data.messages[0].message,
              type: response.data.level.toLowerCase()
            })
          );
          unfoldedTaskSubmittedHandler();
        }
      );
  }, [activityid, closeAlert, unfoldedTaskSubmittedHandler, dispatch, showOverlay]);

  const submitReport = useCallback(() => {
    showOverlay();
    const submissionDate = isTaskTypeAllgemein
      ? new Date()
      : convertUTCDateToLocalDate(reportFormStateRef.current?.date);
    const promises = [
      uploadDate(submissionDate, {
        regardingobjectid,
        uds_aufgabentyp_fuhrpark,
        activityid,
        statuscode: 2
      }),
      reportFormStateRef.current && Object.values(reportFormStateRef.current?.files)
        ? uploadCompanyDocuments(
            Object.values(reportFormStateRef.current?.files),
            regardingobjectid?.attributeValue?.logicalName || '',
            regardingobjectid?.attributeValue?.id || '',
            descriptionDependingOnTaskType(uds_aufgabentyp_fuhrpark?.attributeValue?.value || 0) || ''
          )
        : null
    ];
    showOverlay();
    closeAlert();

    Promise.all(promises)
      .then(() => {
        unfoldedTaskSubmittedHandler();

        dispatch(
          setSnakbar({
            isOpen: true,
            message: isTaskTypeAllgemein
              ? 'Die Aufgabe wurde als erledigt markiert'
              : 'Die Aufgabe wurde erfolgreich erledigt',
            type: 'success'
          })
        );
      })
      .catch(err => {
        console.log(err);
        dispatch(
          setSnakbar({
            isOpen: true,
            message: 'Änderungen wurden nicht übernommen.',
            type: 'error'
          })
        );
      })
      .finally(hideOverlay);
    reportFormStateRef.current = null;
  }, [
    regardingobjectid,
    activityid,
    uds_aufgabentyp_fuhrpark,
    closeAlert,
    dispatch,
    isTaskTypeAllgemein,
    unfoldedTaskSubmittedHandler,
    showOverlay,
    hideOverlay
  ]);

  const openReportModal = useCallback(
    (taskType: any) => {
      setIsMenuOpen(true);
      const alertData = {
        title: `${taskType?.attributeValue?.label} Prüfbericht hochladen `,
        children: (
          <UploadReport
            taskTypeCode={taskType?.attributeValue?.value}
            onFormComplete={onReportFormComplete}
            onSubmitReport={submitReport}
          />
        ),
        buttons: [
          {
            type: 'cancel',
            title: 'Abbrechen',
            action: closeAlert,
            hide: true
          }
        ]
      };

      dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
    },
    [closeAlert, dispatch, onReportFormComplete, setIsMenuOpen, submitReport]
  );

  const handleRestoreTask = useCallback(() => {
    restoreTask({
      Id: activityid.attributeValue
    }).then(response => {
      unfoldedTaskSubmittedHandler();
      dispatch(
        setSnakbar({
          isOpen: true,
          message: response.data.isSuccess ? 'Die Aufgabe wurde als offen markiert' : response.data.messages[0].message,
          type: response.data.level.toLowerCase()
        })
      );
    });
  }, [activityid, dispatch, unfoldedTaskSubmittedHandler]);

  const openUploadChecklistModal = useCallback(() => {
    setIsMenuOpen(true);
    const alertData = {
      title: 'Checkliste für Fahrzeugrückgabe',
      children: <ChecklistUpload onSubmit={submitChecklistReport} onFormComplete={onReportFormComplete} />,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert,
          hide: true
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  }, [closeAlert, dispatch, submitChecklistReport, onReportFormComplete, setIsMenuOpen]);

  const openVehicleReviewModal = useCallback(() => {
    closeAlert();
    setIsMenuOpen(true);
    const alertData = {
      title: 'Kfz-Gutachten beauftragen',
      children: (
        <OrderVehicleReport
          phaseInfo={vir_phaseinfo?.phaseInfo}
          fuhrparkId={new_fuhrparkid.attributeValue}
          actionName='FormFilled'
          taskId={activityid.attributeValue}
          closeAlert={closeAlert}
          callback={unfoldedTaskSubmittedHandler}
        />
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert,
          hide: true
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  }, [vir_phaseinfo, new_fuhrparkid, activityid, closeAlert, dispatch]);

  const { fetch } = useFetch({
    loadingKey: 'setting phase',
    isShowSuccessSnackbar: true,
    successMessage: 'Die Daten wurden erfolgreich übermittelt'
  });

  const onRestore = async () => {
    dispatch(toggleModal(MODALS.alert, null));

    await fetch({
      requestConfig: fleetLeaseEndRequestConfig.setVirAction({
        actionName: 'Restore',
        fuhrparkId: new_fuhrparkid?.attributeValue,
        taskId: activityid?.attributeValue,
        address: vir_phaseinfo?.phaseInfo?.address || '',
        name: vir_phaseinfo?.phaseInfo?.person || '',
        phone: vir_phaseinfo?.phaseInfo?.phone || ''
      })
    });

    unfoldedTaskSubmittedHandler();
  };

  const handleDownload = useCallback(async () => {
    showOverlay();
    await sharepointService
      .downloadFileById(checklistFileData.fileId)
      .then(({ data }) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(new Blob([data]));
        a.download = checklistFileData.name;
        a.click();
      })
      .catch(err => console.log(err))
      .finally(hideOverlay);
  }, [checklistFileData.fileId, checklistFileData.name, hideOverlay, showOverlay]);

  const pushAdress = useCallback(
    (id: string) => {
      history.push(`${PAGES.DAMAGE}/schaden/?id=${id}`);
    },
    [history]
  );

  const handleNoNeedReplacement = useCallback(() => {
    closeAlert();
    showOverlay();
    noNeedReplacementVehicle(activityid.attributeValue, null).then(response => {
      unfoldedTaskSubmittedHandler();
      dispatch(
        setSnakbar({
          isOpen: true,
          message: response.data.isSuccess
            ? 'Deine Entscheidung wurde erfolgreich gespeichert'
            : response.data.messages[0].message,
          type: response.data.level.toLowerCase()
        })
      );
    });
  }, [activityid, closeAlert, dispatch, showOverlay, unfoldedTaskSubmittedHandler]);

  const openNoNeedReplacementModal = useCallback(() => {
    setIsMenuOpen(true);
    const alertData = {
      title: 'Bestätigung',
      children: (
        <NoReplacementVehicle
          onSubmit={handleNoNeedReplacement}
          onCancel={closeAlert}
          plateNumber={regardingobjectid.attributeValue?.name || ''}
        />
      ),
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert,
          hide: true
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  }, [closeAlert, dispatch, handleNoNeedReplacement, regardingobjectid, setIsMenuOpen]);

  const redirectToConfigureVehicle = useCallback(() => {
    if (isFleetManager) {
      if (new_fahrerid.attributeValue) {
        history.push(
          `${PAGES.NEW_REFERENCE_CONFIGURATIONS}?driverId=${encodeBase64(
            new_fahrerid.attributeValue.id
          )}&redirect=${true}`
        );
      } else {
        history.push(`${PAGES.EMPLOYEE_CONFIGURATIONS}?newConfig=${true}`);
      }
    } else {
      history.push(`${PAGES.NEW_CONFIGURATIONS}?redirect=${true}`);
    }
  }, [history, isFleetManager, new_fahrerid]);

  const redirectToOrders = useCallback(() => {
    isFleetManager
      ? history.push(`${PAGES.ORDER_DETAILS}?id=${encodeBase64(rvo_phaseinfo.configurationId.id)}`)
      : history.push(`${PAGES.CONFIGURATION_DETAILS}?id=${encodeBase64(rvo_phaseinfo.configurationId.id)}`);
  }, [history, isFleetManager, rvo_phaseinfo]);

  const handleSubmit = useCallback(
    (isWithoutProtocol: boolean, returnDate: string, files: File[]) => {
      closeAlert();
      showOverlay();
      vr_phaseinfo?.lafId &&
        uploadProtocol(
          regardingobjectid.attributeValue?.id || '',
          vr_phaseinfo.lafId,
          files,
          isWithoutProtocol,
          returnDate
        ).then(response => {
          unfoldedTaskSubmittedHandler();
          dispatch(
            setSnakbar({
              isOpen: true,
              message: response.data.isSuccess
                ? 'Deine Entscheidung wurde erfolgreich gespeichert'
                : response.data.messages[0].message,
              type: response.data.level.toLowerCase()
            })
          );
        });
    },
    [
      closeAlert,
      dispatch,
      regardingobjectid.attributeValue?.id,
      showOverlay,
      unfoldedTaskSubmittedHandler,
      vr_phaseinfo?.lafId
    ]
  );

  const openUploadProtocolModal = useCallback(() => {
    setIsMenuOpen(true);
    const alertData = {
      title: 'Übergabeprotokoll hochladen',
      children: <UploadProtocol onSubmit={handleSubmit} />,
      buttons: [
        {
          type: 'cancel',
          title: 'Abbrechen',
          action: closeAlert,
          hide: true
        }
      ],
      allButtonsHidden: true
    };
    dispatch(toggleModal(MODALS.alert, MODAL_NAMES.alert, alertData));
  }, [closeAlert, dispatch, handleSubmit, setIsMenuOpen]);

  const redirectToOrder = useCallback(() => {
    history.push(`${PAGES.ASSIGNMENTS_LEASE_RETURN_PRO}?id=${regardingobjectid.attributeValue?.id}`);
  }, [history, regardingobjectid.attributeValue?.id]);

  const goToDetails = (page: PAGES, id?: string) => {
    if (!id) {
      return;
    }
    window.open(`${page}/?id=${id}`);
  };

  const getButton = useMemo(() => {
    if (
      taskStatusId === StatusId.Cancelled &&
      uds_aufgabentyp_fuhrpark.attributeValue?.value !== UDS_AUFGABENTYP_FUHRPARK.leaseExtension
    ) {
      return;
    }

    const idButtonPrefix = PrefixForId.Table + PrefixForId.Expand + PrefixForId.Button;

    switch (uds_aufgabentyp_fuhrpark.attributeValue?.value) {
      case UDS_AUFGABENTYP_FUHRPARK.allgemein:
        if (taskStatusId === StatusId.Open) {
          return (
            <div className={classes.button_container}>
              <Button onClick={submitReport} id={idButtonPrefix + 'MarkAsDone'}>
                Als erledigt markieren
              </Button>
            </div>
          );
        }
        return (
          !isDriver && (
            <div className={classes.button_container}>
              <Button onClick={handleRestoreTask} id={idButtonPrefix + 'MarkAsNotDone'}>
                Als nicht erledigt markieren
              </Button>
            </div>
          )
        );

      case UDS_AUFGABENTYP_FUHRPARK.reparaturfreigabe:
        if (taskStatusId === StatusId.Open && regardingobjectid.attributeValue?.logicalName === 'incident') {
          return (
            <div className={classes.button_container}>
              <Button
                onClick={() => pushAdress(regardingobjectid.attributeValue?.id || '')}
                id={idButtonPrefix + 'MarkAsNotDone'}
                margin='10px 0 0 0'
              >
                Aufgabe erledigen
              </Button>
            </div>
          );
        }
        break;

      case UDS_AUFGABENTYP_FUHRPARK.uvv:
      case UDS_AUFGABENTYP_FUHRPARK.hu:
        if (taskStatusId === StatusId.Open) {
          return (
            <div className={classes.button_container}>
              <Button
                onClick={() => openReportModal(uds_aufgabentyp_fuhrpark)}
                id={idButtonPrefix + 'UploadTestReport'}
              >
                Prüfbericht hochladen
              </Button>
            </div>
          );
        }
        break;
      case UDS_AUFGABENTYP_FUHRPARK.inspectionDates:
        return (
          <TaskInspectionDatesActionButton
            idButtonPrefix={idButtonPrefix}
            rowData={aufgeklappteAufgabeInfo}
            openReportModal={() => openReportModal(uds_aufgabentyp_fuhrpark)}
          />
        );

      case UDS_AUFGABENTYP_FUHRPARK.checklist:
        if (taskStatusId === StatusId.Open || taskStatusId === StatusId.Postponed) {
          return (
            <div className={classes.button_container}>
              <Button secondary right='10' onClick={openUploadChecklistModal} id={idButtonPrefix + 'UploadChecklist'}>
                <Flex align='center'>
                  <UploadTestReportIcon width='15' height='20' />
                  <Text color={theme.colors.blue} left='5'>
                    Checkliste hochladen
                  </Text>
                </Flex>
              </Button>
              <Button onClick={handleDownload} id={idButtonPrefix + 'DownloadChecklist'}>
                <Flex align='center'>
                  <Download fill='white' />
                  <Text left='5'>Checkliste herunterladen</Text>
                </Flex>
              </Button>
            </div>
          );
        }
        break;

      case UDS_AUFGABENTYP_FUHRPARK.replacement:
        switch (rvo_state) {
          case REPLACEMENT_VEHICLE_PHASES.open:
            return (
              <div className={classes.button_container}>
                {isFleetManager && (
                  <Button secondary onClick={openNoNeedReplacementModal}>
                    Keine Ersatzbeschaffung
                  </Button>
                )}
                <Button onClick={redirectToConfigureVehicle}>Fahrzeug konfigurieren</Button>
              </div>
            );
          case REPLACEMENT_VEHICLE_PHASES.closedWithoutVehicle:
            return (
              <div className={classes.button_container}>
                <Button onClick={redirectToConfigureVehicle}>Trotzdem konfigurieren</Button>
              </div>
            );
          case REPLACEMENT_VEHICLE_PHASES.processing:
            return (
              <div className={classes.button_container}>
                <Button onClick={redirectToConfigureVehicle}>Weitere Konfiguration starten</Button>
              </div>
            );
          case REPLACEMENT_VEHICLE_PHASES.closedVehicleOrdered:
            return (
              <div className={classes.button_container}>
                <Button onClick={redirectToOrders}>Zur Bestellung →</Button>
              </div>
            );
          case REPLACEMENT_VEHICLE_PHASES.closedVehicleDelivered:
            return (
              <div className={classes.button_container}>
                <Button
                  onClick={() => history.push(`${PAGES.MY_VEHICLES_DETAILS}?id=${rvo_phaseinfo.orderedVehicle.id}`)}
                >
                  Zum Fahrzeug →
                </Button>
              </div>
            );
        }
        break;

      case UDS_AUFGABENTYP_FUHRPARK.return:
        if (taskStatusId && [StatusId.Open, StatusId.Postponed].includes(taskStatusId)) {
          return (
            <div className={classes.button_container}>
              <Button onClick={redirectToOrder} id={'createOrder'}>
                Auftrag erstellen
              </Button>
            </div>
          );
        }
        if (taskStatusId === StatusId.InProgress) {
          return (
            <div className={classes.button_container}>
              <Button secondary right='10' onClick={redirectToOrder} id={'viewOrder'}>
                Auftrag ansehen
              </Button>
              <Button onClick={openUploadProtocolModal} id={'uploadHandoverProtocol'}>
                Übergabeprotokoll hochladen
              </Button>
            </div>
          );
        }
        break;

      case UDS_AUFGABENTYP_FUHRPARK.leaseExtension:
        return (
          <ButtonsForLeaseEnd
            currentPhase={lva_state}
            isMobexoEmployee={isMobexoEmployee}
            setIsMenuOpen={setIsMenuOpen}
            plateNumber={regardingobjectid}
            refresh={unfoldedTaskSubmittedHandler}
            leaseExtensionId={lva_phaseinfo?.lvaId}
            taskId={activityid?.attributeValue || ''}
            phaseInfo={lva_phaseinfo?.specificPhaseInfo}
            files={lva_phaseinfo?.files?.Verlängerungangebot}
            leaseEndDate={uds_lease_return_date?.attributeValue || ''}
            taskStatusId={taskStatusId}
            mileage={lva_phaseinfo?.specificPhaseInfo?.mileagePA}
          />
        );

      case UDS_AUFGABENTYP_FUHRPARK.appraise:
        if (vir_state && [VehicleInitialReviewPhases.Phase1, VehicleInitialReviewPhases.Phase6].includes(vir_state)) {
          return (
            <div id={'bookAppraisalButtton'} className={classes.button_container}>
              <Button onClick={openVehicleReviewModal}>Gutachten buchen</Button>
            </div>
          );
        }
        if (vir_state && vir_state === VehicleInitialReviewPhases.Phase4) {
          return (
            <div id={'bookAnywayButton'} className={classes.button_container}>
              <Button onClick={onRestore}>Trotzdem buchen</Button>
            </div>
          );
        }
    }
  }, [
    openUploadProtocolModal,
    redirectToOrder,
    uds_aufgabentyp_fuhrpark,
    openReportModal,
    submitReport,
    handleRestoreTask,
    taskStatusId,
    isDriver,
    handleDownload,
    openUploadChecklistModal,
    theme,
    isFleetManager,
    openNoNeedReplacementModal,
    redirectToOrders,
    redirectToConfigureVehicle,
    regardingobjectid,
    rvo_state,
    rvo_phaseinfo,
    history,
    pushAdress,
    isMobexoEmployee,
    lva_state,
    setIsMenuOpen,
    unfoldedTaskSubmittedHandler,
    lva_phaseinfo,
    activityid,
    uds_lease_return_date
  ]);

  const getTooltipText = () => {
    return (
      <div>
        <Text>Letzte Änderung:</Text>
        <Text bold>{last_editor.attributeValue?.name || ''}</Text>
        {isTaskPostponed && (
          <Flex>
            <Text as='span'>Verschoben auf</Text>
            &nbsp;
            <Text bold>{formatDate(uds_b2cpostponedate?.attributeValue || '')}</Text>
          </Flex>
        )}
      </div>
    );
  };

  const getDescription = () => {
    if (uds_aufgabentyp_fuhrpark?.attributeValue?.value === TaskTypeId.InspectionDates) {
      if (uds_b2c_portal_status.attributeValue?.value === StatusId.Done) {
        const descriptionDate = moment(description.attributeValue);
        return descriptionDate.isValid()
          ? `Prüftermin wurde am ${descriptionDate.format('DD.MM.YYYY')} durchgeführt.`
          : '';
      }

      return <DescriptionRender />;
    }
    return <DescriptionFromServer description={upperText} />;
  };

  return (
    <section
      id={PrefixForId.Table + PrefixForId.Items + PrefixForId.Container + index}
      className={classNames(classes.aufgeklappte_aufgabe, 'table')}
    >
      <div
        className={classes.close_icon_wrapper}
        onClick={closeAufgeklappteAufgabe}
        id={PrefixForId.Table + PrefixForId.Expand + PrefixForId.Button + PrefixForId.Close}
      >
        <CollapseIcon />
      </div>
      <div className={classes.img_container}>
        <img
          src={imgDependingOnTypeTask(uds_aufgabentyp_fuhrpark.attributeValue?.value || 0)}
          alt='aufgeklappte aufgabe'
          className={classes.img}
          id={PrefixForId.Table + PrefixForId.Expand + PrefixForId.Image}
        />
      </div>

      {isLoading ? (
        <SpinnerContainer minHeight='200px' />
      ) : (
        <div className={classes.container}>
          {isEditing ? (
            <EditTask onCancel={onCancelTaskEdit} taskData={editTaskData} refresh={unfoldedTaskSubmittedHandler} />
          ) : (
            <>
              <div className={classes.list_container}>
                <ul className={classes.list}>
                  <li className={classNames(classes.item, isMobileWidth && classes.title_item)}>
                    <div>
                      {!isMobileWidth && <p className={classes.item_content_top}>Aufgabe</p>}
                      <p
                        className={classNames(
                          classes.item_content_bottom,
                          classes.item_rows_wrapper,
                          classes.item_subject
                        )}
                        id={PrefixForId.Table + PrefixForId.Expand + 'Subject'}
                      >
                        {subject?.attributeValue}
                      </p>

                      {isMobileWidth && (
                        <Text
                          as='span'
                          id={PrefixForId.Table + PrefixForId.Expand + 'UdsB2CPortalStatus'}
                          style={{ color: statusColor }}
                        >
                          {uds_b2c_portal_status?.attributeValue?.label}
                        </Text>
                      )}
                    </div>

                    {isMobileWidth && getIsDisplayMoreMenu() && (
                      <MoreMenu
                        menuItems={menuItems}
                        hide={!showMenu}
                        dotsColor='grey900'
                        idEnding={index}
                        width={160}
                      />
                    )}
                  </li>
                </ul>
                <ul className={classes.list}>
                  {isMobileWidth && (
                    <li className={classNames(classes.item, classes.mobile_data_container)}>
                      <div className={classNames(classes.item_rows_wrapper)}>
                        {!!new_name?.attributeValue && (
                          <>
                            <div className={classes.item_icon_wrapper}>
                              <Car color={theme.colors.grey900} />
                            </div>
                            <Text
                              id={PrefixForId.Table + PrefixForId.Expand + 'RegardingObjectId'}
                              underline={!!new_fuhrparkid?.attributeValue}
                              onClick={() =>
                                goToDetails(PAGES.MY_VEHICLES_DETAILS, new_fuhrparkid?.attributeValue || '')
                              }
                            >
                              {new_name?.attributeValue}
                            </Text>
                          </>
                        )}
                      </div>

                      <div className={classNames(classes.item_rows_wrapper, classes.date_field)}>
                        <div className={classes.item_icon_calendar_wrapper}>
                          <CalendarWithCheckMarkIcon color={theme.colors.grey900} />
                        </div>
                        <p id={PrefixForId.Table + PrefixForId.Expand + 'Scheduledend'}>
                          {formatDate(scheduledend?.attributeValue || '')}
                        </p>
                      </div>
                    </li>
                  )}
                  <li className={classes.item}>
                    <div className={classes.item_rows_wrapper}>
                      <div className={classes.item_icon_wrapper}>
                        <UserIcon color={theme.colors.grey900} />
                      </div>
                      <Text
                        display='flex'
                        overflow='hidden'
                        width={'100%'}
                        as={'span'}
                        id={PrefixForId.Table + PrefixForId.Expand + 'UdsExecutorContactId'}
                        underline={uds_executor_contactid?.attributeValue?.logicalName === 'contact'}
                        onClick={() =>
                          goToDetails(
                            PAGES.MY_DRIVERS_DETAILS,
                            uds_executor_contactid?.attributeValue?.logicalName === 'contact'
                              ? uds_executor_contactid?.attributeValue?.id
                              : undefined
                          )
                        }
                      >
                        <EllipsisWithTooltip
                          tooltip={uds_executor_contactid?.attributeValue?.name || ''}
                          maxWidth={!isMobileWidth ? '75%' : undefined}
                        >
                          <span>{uds_executor_contactid?.attributeValue?.name || ''}</span>
                        </EllipsisWithTooltip>
                      </Text>
                    </div>

                    {!isMobileWidth && (
                      <div className={classNames(classes.item_rows_wrapper, classes.item_content_bottom)}>
                        {!!new_name?.attributeValue && (
                          <>
                            <div className={classes.item_icon_wrapper}>
                              <Car color={theme.colors.grey900} />
                            </div>
                            <Text
                              id={PrefixForId.Table + PrefixForId.Expand + 'RegardingObjectId'}
                              underline={!!new_fuhrparkid?.attributeValue}
                              onClick={() =>
                                goToDetails(PAGES.MY_VEHICLES_DETAILS, new_fuhrparkid?.attributeValue || '')
                              }
                            >
                              {new_name?.attributeValue}
                            </Text>
                          </>
                        )}
                      </div>
                    )}
                  </li>
                  <li className={classes.item}>
                    <div className={classes.item_rows_wrapper}>
                      <div className={classes.item_icon_wrapper}>
                        <TaskTypeIcon
                          type={uds_aufgabentyp_fuhrpark.attributeValue?.value}
                          color={theme.colors.grey900}
                        />
                      </div>
                      <p id={PrefixForId.Table + PrefixForId.Expand + 'UdsAufgabentypFuhrpark'}>
                        {addTooltipToString(uds_aufgabentyp_fuhrpark?.attributeValue?.label || '', 20)}
                      </p>
                    </div>

                    {!isMobileWidth && (
                      <div
                        className={classNames(
                          classes.item_rows_wrapper,
                          classes.item_content_bottom,
                          classes.item_calendar_wrapper
                        )}
                      >
                        <div className={classes.item_icon_calendar_wrapper}>
                          <CalendarWithCheckMarkIcon color={theme.colors.grey900} />
                        </div>
                        <p id={PrefixForId.Table + PrefixForId.Expand + 'Scheduledend'}>
                          {formatDate(scheduledend?.attributeValue || '')}
                        </p>
                      </div>
                    )}
                  </li>
                  {!isMobileWidth && (
                    <div className={classes.tool_wrapper}>
                      <li className={classes.item_status_wrapper}>
                        <Tooltip title={getTooltipText()} arrow>
                          <div>
                            <p
                              className={classes.item_content_status}
                              style={{ backgroundColor: statusColor }}
                              id={PrefixForId.Table + PrefixForId.Expand + 'UdsB2CPortalStatus'}
                            >
                              {uds_b2c_portal_status?.attributeValue?.label}
                            </p>
                          </div>
                        </Tooltip>
                      </li>
                      {getIsDisplayMoreMenu() && (
                        <li className={classes.item_menu_wrapper}>
                          <MoreMenu
                            menuItems={menuItems}
                            hide={!showMenu}
                            dotsColor='grey900'
                            idEnding={index}
                            width={160}
                          />
                        </li>
                      )}
                    </div>
                  )}
                </ul>
              </div>

              <div className={classes.footer}>
                <div
                  className={isRVOPhase4 ? classes.phases_description : classes.description}
                  id={PrefixForId.Table + PrefixForId.Expand + 'Description'}
                >
                  <Flex bottom='15'>{getDescription()}</Flex>
                  {isDescriptionRender ? (
                    isRVOPhase4 ? (
                      <ConfigurationStatusRender />
                    ) : (
                      <DescriptionRender />
                    )
                  ) : (
                    <DescriptionFromServer description={lowerText} />
                  )}
                </div>
              </div>
              {getButton}
            </>
          )}
        </div>
      )}
    </section>
  );
};
