import { AnyObject } from 'chart.js/dist/types/basic';
import { useMemo } from 'react';

export const useLeasingWidgetsLayout = (car: AnyObject) => {
  const { widgetSetings } = car;
  const showVehicleReturnWidget = widgetSetings.showVrWidget;
  const showReplacementVehicleOrderingWidget = widgetSetings.showRvoWidget;
  const showCheckListWidget = widgetSetings.showChecklistWidget;
  const showLeaseExtensionDecisionWidget = widgetSetings.showLvaWidget;
  const showVehicleInitialReviewWidget = widgetSetings.showVirWidget;

  const amountOfWidgetsToShow = useMemo(() => {
    const widgetsRenderStateArray = [
      showVehicleReturnWidget,
      showReplacementVehicleOrderingWidget,
      showCheckListWidget,
      showLeaseExtensionDecisionWidget,
      showVehicleInitialReviewWidget
    ];

    return widgetsRenderStateArray.filter(widget => widget).length;
  }, [
    showVehicleReturnWidget,
    showReplacementVehicleOrderingWidget,
    showCheckListWidget,
    showLeaseExtensionDecisionWidget,
    showVehicleInitialReviewWidget
  ]);

  return {
    showVehicleReturnWidget,
    showReplacementVehicleOrderingWidget,
    showCheckListWidget,
    showLeaseExtensionDecisionWidget,
    showVehicleInitialReviewWidget,
    amountOfWidgetsToShow
  };
};
