import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { LeasingOffer, User } from 'common/interfaces';
import { ItemProps } from 'components/product/product-view.props';
import { useEffect, useState } from 'react';
import { getFahrerLevel } from 'services/driver-level-service';
import { getBudget } from 'utils/get-budget';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { BudgetData } from 'pages/details/order-widget/order-widget.props';
import { useGetUser } from 'hooks/react-query/use-get-user/use-get-user';
import { MobilityBudgetProps } from 'components/aufgaben-component/task.prop';

export const useBudgetDataAndFuelCost = (
  isGlobalReferenceVehicle: boolean,
  vehicleOfferData: ItemProps,
  leasingOfferData: LeasingOffer,
  driverLevelId: string | null,
  driverId: string | null
) => {
  const user = useTypedSelector<User>(state => state['app'].user);

  const [budgetData, setBudgetData] = useState<BudgetData | null>(null);
  const [driverLevel, setDriverLevel] = useState<string | null>(driverLevelId);

  const { userData } = useGetUser(driverId || user.id, false, !driverLevel);

  const fuelCost = getFuelCost(isGlobalReferenceVehicle, budgetData, vehicleOfferData, leasingOfferData);

  const modifyResponse = (response: AxiosResponse<MobilityBudgetProps>) => {
    return response.status === 200 ? response.data : undefined;
  };

  const { data: driverLevelData } = useQuery(
    ['get-driver-level-data', driverLevel],
    () => getFahrerLevel(driverLevel),
    {
      select: modifyResponse,
      enabled: !!driverLevel && vehicleOfferData.hasOwnProperty('uds_enginetypecode')
    }
  );

  useEffect(() => {
    if (userData) {
      setDriverLevel(userData.uds_carpolicynutzerid?.id || null);
    }
  }, [JSON.stringify(userData)]);

  useEffect(() => {
    if (driverLevelData) {
      const budget = getBudget(vehicleOfferData.uds_enginetypecode_value, driverLevelData, user.companyId);
      const surcharge = driverLevelData.uds_zuzahlung
        ? driverLevelData.uds_zuzahlungtyp === 100000000
          ? budget * (driverLevelData.uds_zuzahlung / 100)
          : driverLevelData.uds_zuzahlung
        : 0;
      const includeFuelCost = driverLevelData.uds_inklusivekraftstoffkosten || false;
      const isDriverSupplementMandatory = driverLevelData.uds_isdriver_supplement_mandatory || false;

      setBudgetData({ budget, surcharge, includeFuelCost, isDriverSupplementMandatory });
    }
  }, [JSON.stringify(driverLevelData)]);

  return {
    budgetData,
    fuelCost
  };
};

const getFuelCost = (
  isGlobalReferenceVehicle: boolean,
  budgetData: BudgetData | null,
  vehicleOfferData: ItemProps,
  leasingOfferData: LeasingOffer
) => {
  if (isGlobalReferenceVehicle) {
    return budgetData?.includeFuelCost && leasingOfferData.uds_calc_monthly_costs_incl_fuel_value
      ? leasingOfferData.uds_calc_monthly_costs_incl_fuel_value - leasingOfferData.new_mobexoangebotgesamtrate_value
      : 0;
  }

  return budgetData?.includeFuelCost &&
    vehicleOfferData.uds_laufleistungkm &&
    vehicleOfferData.uds_kalkulatorischekraftstoffkosten_value
    ? (vehicleOfferData.uds_laufleistungkm / 12 / 100) * vehicleOfferData.uds_kalkulatorischekraftstoffkosten_value // fuel cost per month
    : 0;
};
