import { useFetch } from 'hooks/use-fetch/use-fetch';
import { ApiResponse, ResponseModel } from 'common/interfaces';
import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'common/enums';
import { RequestStatuses } from '../../../../constants';
import { vehicleRequestConfig } from 'request-config/vehicle/vehicle.request-config';
import { useQuery as useQueryLocation } from 'hooks';
import { VehicleApprovalPageData, VehicleApprovalPageResponse } from './use-vehicle-approval-page.types';

const modifyResponse = ({ data }: ApiResponse<VehicleApprovalPageResponse>) => {
  if (data.level === RequestStatuses.Success) {
    return { ...data.data.leasingangebote.attributes, ...data.data.fahrzeugangebote.attributes };
  }
  return undefined;
};

const useVehicleApprovalPage = () => {
  const query = useQueryLocation();
  const queryId = query.id as string;
  const { fetch } = useFetch<ResponseModel>({
    isShowLoading: false,
    ...vehicleRequestConfig.getVehicleApprovalPageData(queryId || '')
  });

  const { data: vehicleApprovalPageData } = useQuery<
    ApiResponse<VehicleApprovalPageResponse>,
    unknown,
    VehicleApprovalPageData | undefined
  >([ReactQueryKeys.GetVehicleApprovalPageData, queryId], () => fetch(), {
    enabled: !!queryId,
    select: modifyResponse
  });

  return { vehicleApprovalPageData };
};

export default useVehicleApprovalPage;
