import useUrlParams from './use-url-params';
import React, { useMemo } from 'react';
import { InfoContainerProps } from '../options-component/info-container/info-container.types';
import { VehicleIcon } from 'components/icons-new/vehicle';
import { Car } from 'components/icons-new/car';
import { EngineIcon } from 'components/icons-new/engine';
import { CarPriceIcon } from 'components/icons-new/car-price';
import { TransmissionIcon } from 'components/icons-new/transmission';
import { BillIcon } from 'components/icons-new/bill';
import { CarBrushIcon } from 'components/icons-new/car-brush';
import toMoney from 'utils/toMoney';
import useCarConfiguratorVehicleDetails from '../hooks/use-car-configurator-vehicle-details';
import { KW_TO_HP_COEFFICIENT, TAXES_COEFFICIENT } from '../../../constants';
import useChosenValues from '../hooks/use-chosen-values';
import { GasStationIcon } from 'components/icons-new/gas-station';
import { WalletIcon } from 'components/icons-new/wallet';
import { useTheme } from 'styled-components';
import { VehicleDetails } from '../hooks/use-car-configurator-vehicle-details/use-car-configurator-vehicle-details.types';

const useInfoContainersData = () => {
  const theme = useTheme();
  const { driverId, vehicleId } = useUrlParams();
  const { vehicleDetails } = useCarConfiguratorVehicleDetails(driverId, vehicleId || '');
  const { chosenValues } = useChosenValues();

  const sumOfOptions = chosenValues.options?.reduce((accumulator, option) => accumulator + option.retailPrice, 0) || 0;
  const grossListPriceIncludingSpecialEquipment = (vehicleDetails?.new_listenpreis_brutto?.value || 0) + sumOfOptions;
  const netListPriceIncludingSpecialEquipment = grossListPriceIncludingSpecialEquipment / TAXES_COEFFICIENT;
  const expectedReferenceRate = getExpectedReferenceRate({
    netListPrice: netListPriceIncludingSpecialEquipment,
    leasingFactor: vehicleDetails?.uds_leasingfaktor || 0,
    leaseServices: vehicleDetails?.lease_services || 0
  });

  const priceAndFuelCost = expectedReferenceRate + (vehicleDetails?.monthly_fuel_costs || 0);
  const outOfBudget = priceAndFuelCost > (vehicleDetails?.budget || 0) + (vehicleDetails?.absoluteDownPayment || 0);
  const downPayment = getDownPayment(priceAndFuelCost, vehicleDetails);

  const infoContainerData: InfoContainerProps[] | undefined = useMemo(() => {
    if (vehicleDetails) {
      const infoContainerArray = [
        {
          content: [
            {
              value: vehicleDetails.uds_carbrandid?.name || '-',
              icon: <VehicleIcon width={34} height={16} />
            }
          ],
          gridArea: 'A',
          label: 'Marke'
        },
        {
          content: [
            {
              value: vehicleDetails.uds_carmodelid?.name || '-',
              icon: <Car width={34} height={25} />
            }
          ],
          gridArea: 'B',
          label: 'Modell'
        },
        {
          content: [
            {
              value: vehicleDetails.uds_modellspezifikation || '-',
              icon: <EngineIcon width={34} height={22} />
            }
          ],
          gridArea: 'C',
          label: 'Antrieb'
        },
        {
          content: [
            {
              value: `${((vehicleDetails.uds_leistungkw || 0) * KW_TO_HP_COEFFICIENT).toFixed(0)} PS`,
              icon: <EngineIcon width={34} height={22} />
            }
          ],
          gridArea: 'D',
          label: 'Leistung'
        },
        {
          content: [
            {
              value: toMoney(grossListPriceIncludingSpecialEquipment),
              icon: <CarPriceIcon width={34} height={28} />,
              subLabel: 'Bruttolistenpreis inkl. Sonderausstattung'
            }
          ],
          gridArea: 'E',
          label: 'Fahrzeugpreis'
        },
        {
          content: [
            {
              value: vehicleDetails.uds_getriebe?.label || '-',
              icon: <TransmissionIcon width={29} height={34} />
            }
          ],
          gridArea: 'F',
          label: 'Getriebe'
        },
        {
          content: [
            {
              value: toMoney(vehicleDetails.budget),
              icon: <CarPriceIcon width={34} height={28} />
            }
          ],
          gridArea: 'G',
          label: 'Mobilitätsbudget'
        },
        {
          content: [
            {
              value: toMoney(vehicleDetails.monthly_fuel_costs),
              icon: <GasStationIcon width={34} height={28} />
            }
          ],
          gridArea: 'H',
          label: 'Kalk. Kraftstoffkost.'
        },
        {
          content: [
            {
              value: toMoney(expectedReferenceRate),
              icon: <BillIcon width={34} height={34} color={outOfBudget ? theme.colors.pink : theme.colors.blue} />,
              subLabel: outOfBudget ? 'Budget überschritten' : 'Im Budget'
            }
          ],
          gridArea: 'I',
          outOfBudget,
          label: 'Voraussichtliche Referenzrate'
        },
        {
          content: [
            {
              value: toMoney(downPayment),
              icon: <WalletIcon width={34} height={34} />
            }
          ],
          gridArea: 'J',
          label: 'Eigenzuzahlung'
        },
        {
          content: [
            {
              value: 'Link',
              icon: <CarBrushIcon width={34} height={28} />
            }
          ],
          gridArea: 'K',
          label: 'Zum Herstellerkonfigurator',
          link: vehicleDetails.manufacturers_configurator_link || ''
        }
      ];

      if (!vehicleDetails.manufacturers_configurator_link) {
        infoContainerArray.pop();
      }

      return infoContainerArray;
    }
  }, [vehicleDetails, expectedReferenceRate, grossListPriceIncludingSpecialEquipment, outOfBudget, downPayment]);

  return {
    infoContainerData,
    vehicleDetails,
    grossListPriceIncludingSpecialEquipment,
    expectedReferenceRate,
    outOfBudget
  };
};

const getDownPayment = (priceAndFuelCost: number, vehicleDetails?: VehicleDetails) => {
  if (!vehicleDetails) {
    return 0;
  }
  if (vehicleDetails.isdriver_supplement_mandatory) {
    return priceAndFuelCost >= vehicleDetails.absoluteDownPayment
      ? vehicleDetails.absoluteDownPayment
      : priceAndFuelCost;
  }
  if (priceAndFuelCost - vehicleDetails.budget <= 0) {
    return 0;
  }

  return priceAndFuelCost - vehicleDetails.budget >= vehicleDetails.absoluteDownPayment
    ? vehicleDetails.absoluteDownPayment
    : priceAndFuelCost - vehicleDetails.budget;
};

interface GetExpectedReferenceRateProps {
  netListPrice: number;
  leasingFactor: number;
  leaseServices: number;
}

export const getExpectedReferenceRate = ({
  netListPrice,
  leasingFactor,
  leaseServices
}: GetExpectedReferenceRateProps) => {
  return (
    (netListPrice * leasingFactor) / 100 + leaseServices
  );
};

export default useInfoContainersData;
